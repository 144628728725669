/* eslint-disable no-unused-vars */
import styles from "./Home.module.scss";
import { Footer } from "../Footer/Footer";
import { useState, useEffect } from "react";
import LanguageSwitcher from "../../components/LanguageSwitcher/LanguageSwitcher";
// import {
//   SlSocialInstagram,
//   SlSocialSpotify,
//   SlSocialYoutube,
// } from "react-icons/sl";
// import { PiTiktokLogoThin } from "react-icons/pi";

const Home = () => {
  const [closeModal, setCloseModal] = useState(true);

  useEffect(() => {
    const hasPopUpBeenShown = localStorage.getItem("popUpShown");
    if (hasPopUpBeenShown) {
      setCloseModal(false);
    }

    const handleUnload = () => {
      localStorage.removeItem("popUpShown"); // Clear the specific local storage key
    };

    window.addEventListener("beforeunload", handleUnload);

    return () => {
      window.removeEventListener("beforeunload", handleUnload);
    };
  }, []);

  // const handleCloseModal = () => {
  //   setCloseModal(false);
  //   localStorage.setItem("popUpShown", "true");
  // };

  return (
    <section className={styles.galeri}>
      <div className={styles.galeriBox5}></div>
      {/* {closeModal && <Footer handleCloseModal={handleCloseModal} />} */}
      <div className={styles.languageSwitcher}>
        {/* <div style={{}}>
          <a
            href="https://www.youtube.com/channel/UCvI6DclFq5DI6wowPGxvxzg"
            target="_blank"
            rel="noopener noreferrer"
          >
            <SlSocialYoutube size={25} className={styles.fbIcon} />
          </a>
          <a
            href="https://www.instagram.com/ozzyfromtheblock/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <SlSocialInstagram size={25} />
          </a>
          <a
            href="https://open.spotify.com/artist/7uYFKmODOjK48vXyNRgKmQ?si=_k_VVPVZR6GwYanUtFQR7g"
            target="_blank"
            rel="noopener noreferrer"
          >
            <SlSocialSpotify size={25} />
          </a>
          <a
            href="https://www.tiktok.com/@osmantugsal"
            target="_blank"
            rel="noopener noreferrer"
          >
            <PiTiktokLogoThin size={30} />
          </a>
        </div> */}
        <LanguageSwitcher />
      </div>
    </section>
  );
};

export default Home;
