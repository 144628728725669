import styles from "./Performance.module.scss";
import { useTranslation } from "react-i18next";
const PerformanceVideos = () => {
  const { t } = useTranslation("home");
  return (
    <div className={styles.showsContainer}>
      <div className={styles.shows}>
        <h1 className={styles.headerText}>{t("performance.title")} </h1>
        <div className={styles.performanceVideosContainer}>
          <div className={styles.youtubeContainer}>
            <iframe
              width="100%"
              height="100%"
              src="https://www.youtube.com/embed/mpvOHG7Q4gs?si=AnpdIF7hi9lNl5Ca&amp;controls=0&rel=0"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen
            ></iframe>
          </div>
          <div className={styles.youtubeContainer}>
            <iframe
              width="100%"
              height="100%"
              src="https://www.youtube.com/embed/897x6FLt-JM?si=2tyxM_xTWAIFaafl&amp;controls=0&rel=0"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
};
export default PerformanceVideos;
