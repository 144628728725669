import styles from "./MusicVideos.module.scss";
import { useTranslation } from "react-i18next";
const MusicVideos = () => {
  const { t } = useTranslation("home");
  return (
    <div className={styles.musicVideoContainer}>
      <div className={styles.musicVideos}>
        <h1 className={styles.headerText}>{t("musics.title")} </h1>
        <div className={styles.musicRootContainer}>
          <div className={styles.youtubeContainer}>
            <iframe
              width="100%"
              height="100%"
              src="https://www.youtube.com/embed/RRfNFAUjpBs?si=7Yh3BVeoJg814qiA&amp;controls=0&rel=0"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen
            ></iframe>
          </div>
          <div className={styles.youtubeContainer}>
            <iframe
              width="100%"
              height="100%"
              src="https://www.youtube.com/embed/FFPrMmhWWR0?si=7WHuR7kqNdXQQuay&amp;controls=0&rel=0"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen
            ></iframe>
          </div>
          <div className={styles.youtubeContainer}>
            <iframe
              style={{ display: "flex", justifyContent: "center" }}
              width="100%"
              height="100%"
              src="https://www.youtube.com/embed/_BLIfd2pk6o?controls=0&amp;start=1&rel=0"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen
            ></iframe>
          </div>
          <div className={styles.youtubeContainer}>
            <iframe
              width="100%"
              height="100%"
              src="https://www.youtube.com/embed/5vJ21dgPGsE?si=-o4MUuMKMiraxY55&amp;controls=0&rel=0"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen
            ></iframe>
          </div>
          <div className={styles.youtubeContainer}>
            <iframe
              width="100%"
              height="100%"
              src="https://www.youtube.com/embed/Zt966fXl64I?si=uGKFZNTYKlA-oKVg&amp;controls=0&rel=0"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
};
export default MusicVideos;
