/* eslint-disable jsx-a11y/iframe-has-title */
import styles from "./Musics.module.scss";
import { useTranslation } from "react-i18next";
const Musics = () => {
  const { t } = useTranslation("home");
  return (
    <div className={styles.musicContainer}>
      <div className={styles.container}>
        <h2 className={styles.musics}>{t("songs")}</h2>

        <div className={styles.border}></div>
        <div className={styles.half}>
          <iframe
            src="https://open.spotify.com/embed/album/5vktnnKEjZnPxXHxkv4Q2G?utm_source=generator"
            width="100%"
            height="152"
            frameBorder="0"
            allowfullscreen=""
            allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
            loading="lazy"
          ></iframe>
          <iframe
            src="https://open.spotify.com/embed/album/1tZZse8Asy4QmQHkQLhPJf?utm_source=generator"
            width="100%"
            height="152"
            frameBorder="0"
            allowfullscreen=""
            allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
            loading="lazy"
          ></iframe>

          <iframe
            src="https://open.spotify.com/embed/album/13UtbmVSa114PBH4dxCb1T?utm_source=generator"
            width="100%"
            height="152"
            frameBorder="0"
            allowfullscreen=""
            allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
            loading="lazy"
          ></iframe>

          <iframe
            src="https://open.spotify.com/embed/album/1ZYL7tLIWEqhzzDzgP87if?utm_source=generator"
            width="100%"
            height="352"
            frameBorder="0"
            allowfullscreen=""
            allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
            loading="lazy"
          ></iframe>

          <iframe
            src="https://open.spotify.com/embed/album/7jY2lAnE46jaO8agh9gX10?utm_source=generator&theme=0"
            width="100%"
            height="352"
            frameBorder="0"
            allowfullscreen=""
            allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
            loading="lazy"
          ></iframe>
          <iframe
            src="https://open.spotify.com/embed/artist/7uYFKmODOjK48vXyNRgKmQ?utm_source=generator"
            width="100%"
            height="352"
            frameBorder="0"
            allowfullscreen=""
            allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
            loading="lazy"
          ></iframe>
        </div>
      </div>
    </div>
  );
};
export default Musics;
