import styles from "./About.module.scss";
import { useTranslation } from "react-i18next";
const About = () => {
  const { t } = useTranslation("home");
  return (
    <div className={styles.biographyContainer}>
      <div className={styles.bibliography}>
        <h1 className={styles.aboutOsman}>{t("about")} </h1>
        <p className={styles.osmanBio}>{t("about.osman")}</p>
      </div>
    </div>
  );
};
export default About;
