import "./App.css";
import { Route, Routes, BrowserRouter as Router } from "react-router-dom";
import Navbar from "../src/components/Navbar/Navbar";
import Home from "./Pages/Home/Home";
import Musics from "./Pages/Musics/Musics";
import About from "./Pages/About/About";
import Gallery from "./Pages/Gallery/Gallery";
import { Footer } from "./Pages/Footer/Footer";
import Shows from "./Pages/Shows/Shows";
import PerformanceVideos from "./Pages/Performance/Performance";
import MusicVideos from "./Pages/MuzicVideos/MusicVideos";
function App() {
  return (
    <Router class="App">
      <AppContent />
    </Router>
  );
}
function AppContent() {
  return (
    <>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/şarkılar" element={<Musics />} />
        <Route path="/hakkında" element={<About />} />
        <Route path="/galeri" element={<Gallery />} />
        <Route path="/abone-ol" element={<Footer />} />
        <Route path="/şov" element={<Shows />} />
        <Route path="/performans-video" element={<PerformanceVideos />} />
        <Route path="/muzik-video" element={<MusicVideos />} />
      </Routes>
    </>
  );
}
export default App;
