import styles from "./Shows.module.scss";
import { useTranslation } from "react-i18next";
const Shows = () => {
  const { t } = useTranslation("home");
  return (
    <div className={styles.showsContainer}>
      <div className={styles.shows}>
        <h1 className={styles.headerText}>{t("shows")} </h1>
        <p className={styles.showInfo}>{t("show.info")}</p>
      </div>
    </div>
  );
};
export default Shows;
