import styles from "./Footer.module.scss";
import {
  SlSocialInstagram,
  SlSocialSpotify,
  SlSocialYoutube,
} from "react-icons/sl";
import { PiTiktokLogoThin } from "react-icons/pi";
import { AiOutlineClose } from "react-icons/ai";
import { useTranslation } from "react-i18next";
import { useState } from "react";
export const Footer = ({ handleCloseModal }) => {
  const { t } = useTranslation("home");
  const [email, setEmail] = useState("");
  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  return (
    <div className={styles.footerContainer}>
      <div className={styles.footer}>
        <div className={styles.popupHeader}>
          <h1 className={styles.footerText}>{t("stay.in.tune")} </h1>

          <AiOutlineClose
            onClick={() => handleCloseModal()}
            cursor={"pointer"}
            color={"#888"}
            size={20}
          />
        </div>
        <div style={{ width: "100%" }}>
          <form
            method="post"
            className={styles.subscribeForm}
            action="https://app.us10.list-manage.com/subscribe/post?u=bd4b76f73595047285c5cb442&amp;id=fef79de7c7&amp;f_id=0038d4e5f0"
          >
            <p className={styles.subscribeText}>{t("stay.in.tune.info")}</p>

            <div className={styles.inputFieldContainer}>
              <input
                type="email"
                placeholder="Email"
                className={styles.emailInput}
                required
                name="EMAIL"
                value={email}
                onChange={handleEmailChange}
              />
              <button
                type="submit"
                name="subscribe"
                value="Subscribe"
                className={styles.subscribeButton}
              >
                {t("subscribe")}
              </button>
            </div>
          </form>
          <div className={styles.iconContainer}>
            <a
              href="https://www.youtube.com/channel/UCvI6DclFq5DI6wowPGxvxzg"
              target="_blank"
              rel="noopener noreferrer"
            >
              <SlSocialYoutube
                className={styles.icon}
                size={25}
                color="#267EA2"
              />
            </a>
            <a
              href="https://www.instagram.com/ozzyfromtheblock/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <SlSocialInstagram
                className={styles.icon}
                size={25}
                color="#267EA2"
              />
            </a>
            <a
              href="https://open.spotify.com/artist/7uYFKmODOjK48vXyNRgKmQ?si=_k_VVPVZR6GwYanUtFQR7g"
              target="_blank"
              rel="noopener noreferrer"
            >
              <SlSocialSpotify
                className={styles.icon}
                size={25}
                color="#267EA2"
              />
            </a>
            <a
              href="https://www.tiktok.com/@osmantugsal"
              target="_blank"
              rel="noopener noreferrer"
            >
              <PiTiktokLogoThin
                className={styles.icon}
                size={25}
                color="#267EA2"
              />
            </a>{" "}
          </div>
        </div>
      </div>
    </div>
  );
};
