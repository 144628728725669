import styles from "./Gallery.module.scss";
import { useTranslation } from "react-i18next";
const Gallery = () => {
  const { t } = useTranslation("home");
  const osmanImages = [
    require("../../assets/Galeri/osman-4.JPG"),
    require("../../assets/Galeri/osman-2.JPG"),
    require("../../assets/Galeri/osman-3.JPG"),
    require("../../assets/Galeri/osman-8.JPG"),
    require("../../assets/Galeri/osman-10.JPG"),
    require("../../assets/Galeri/osman-11.JPG"),
    require("../../assets/Galeri/osman-7.JPG"),
    require("../../assets/Galeri/osman-5.JPG"),
    require("../../assets/Galeri/osman-6.JPG"),
  ];
  return (
    <div className={styles.galleryContainer}>
      <div className={styles.gallery}>
        <h1 className={styles.galleryText}>{t("gallery.title")} </h1>
        <div className={styles.galleryBox}>
          {osmanImages.map((imgSrc, index) => (
            <div className={styles.imageContainer} key={index}>
              <img src={imgSrc} width="100%" height="100%" alt="osman" />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
export default Gallery;
