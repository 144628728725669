import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  SlSocialInstagram,
  SlSocialSpotify,
  SlSocialYoutube,
} from "react-icons/sl";
import { PiTiktokLogoThin } from "react-icons/pi";
import styles from "./navbar.module.scss";
import { RxHamburgerMenu } from "react-icons/rx";
const Navbar = () => {
  const [scrolled, setScrolled] = useState(false);
  const { t } = useTranslation("home");
  const [showMobil, setShowMobil] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 0);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const navbarClasses = `${styles.navbar} ${scrolled ? styles.scrolled : ""}`;

  return (
    <div className={styles.navbarContainer}>
      <div className={navbarClasses}>
        <div className={styles.rightbar}>
          <div className={styles.logoName}>
            <Link to={"/"}>
              <h3 className={styles.osmanlogo}>Osman Tuğsal</h3>
            </Link>
          </div>
          <div className={styles.rightMenu}>
            {[
              { to: "/", label: t("home") },
              { to: "/hakkında", label: t("about") },
              { to: "/şarkılar", label: t("songs") },
              { to: "/galeri", label: t("gallery") },
              { to: "/şov", label: t("shows") },
              { to: "/performans-video", label: t("performance") },
              { to: "/muzik-video", label: t("musics") },

              // { to: "/iletişim", label: t("contact") },
            ].map((item, index) => (
              <Link key={index} to={item.to}>
                <p>{item.label}</p>
              </Link>
            ))}
            <div className={styles.border}></div>
            <a
              href="https://www.youtube.com/channel/UCvI6DclFq5DI6wowPGxvxzg"
              target="_blank"
              rel="noopener noreferrer"
            >
              <SlSocialYoutube size={15} className={styles.fbIcon} />
            </a>
            <a
              href="https://www.instagram.com/ozzyfromtheblock/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <SlSocialInstagram size={15} />
            </a>
            <a
              href="https://open.spotify.com/artist/7uYFKmODOjK48vXyNRgKmQ?si=_k_VVPVZR6GwYanUtFQR7g"
              target="_blank"
              rel="noopener noreferrer"
            >
              <SlSocialSpotify size={15} />
            </a>
            <a
              href="https://www.tiktok.com/@osmantugsal"
              target="_blank"
              rel="noopener noreferrer"
            >
              <PiTiktokLogoThin size={20} />
            </a>
          </div>
        </div>
        <div className={styles.mobilNav}>
          <div className={styles.logoContainer}>
            <div className={styles.logoName}>
              <Link className={styles.navbarItems} to={"/"}>
                <h3 className={styles.osmanlogo}>Osman Tuğsal</h3>
              </Link>
            </div>
            <RxHamburgerMenu
              size={25}
              onClick={() => setShowMobil(!showMobil)}
            />
          </div>
        </div>
      </div>
      <div className={styles.background}>
        {showMobil && (
          <div className={styles.mobilMenu}>
            {[
              { to: "/", label: t("home") },
              { to: "/hakkında", label: t("about") },
              { to: "/şarkılar", label: t("songs") },
              { to: "/galeri", label: t("gallery") },
              { to: "/şov", label: t("shows") },
              { to: "/performans-video", label: t("performance") },
              { to: "/muzik-video", label: t("musics") },

              // { to: "/iletişim", label: t("contact") },
            ].map((item, index) => (
              <>
                <Link
                  className={styles.navbarItems}
                  onClick={() => setShowMobil(false)}
                  key={index}
                  to={item.to}
                >
                  <p>{item.label}</p>
                </Link>
                <div className={styles.borderMobil}></div>
              </>
            ))}
            <div className={styles.iconContainer}>
              <a
                href="https://www.youtube.com/channel/UCvI6DclFq5DI6wowPGxvxzg"
                target="_blank"
                rel="noopener noreferrer"
              >
                <SlSocialYoutube
                  size={25}
                  color="#fff"
                  className={styles.fbIcon}
                />
              </a>
              <a
                href="https://www.instagram.com/ozzyfromtheblock/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <SlSocialInstagram size={25} color="#fff" />
              </a>
              <a
                href="https://open.spotify.com/artist/7uYFKmODOjK48vXyNRgKmQ?si=_k_VVPVZR6GwYanUtFQR7g"
                target="_blank"
                rel="noopener noreferrer"
              >
                <SlSocialSpotify size={25} color="#fff" />
              </a>
              <a
                href="https://www.tiktok.com/@osmantugsal"
                target="_blank"
                rel="noopener noreferrer"
              >
                <PiTiktokLogoThin size={25} color="#fff" />
              </a>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Navbar;
